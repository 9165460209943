<template>
  <vx-card title="Updated Profile Details" class="mt-base">
    <div>
      <p v-if="taskStatus === 'completed'">
        The task has been already completed !
      </p>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="profileImage">Profile Image</label>
            <br />
            <div class="img-wrapper" v-if="userDetail.profileImage">
              <!-- <vs-avatar
                size="100px"
                :src="userDetail.profileImage"
                id="profileImage"
              />-->
              <img :src="userDetail.profileImage" class="img-fluid" alt />
            </div>
            <vs-avatar size="110px" :src="defaultPP" v-else />
          </div>
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-align="left"
          vs-w="6"
          v-if="userType === 'doctor'"
        >
          <div class="w-full m-5">
            <label for="signature">Signature</label>
            <br />
            <div class="img-wrapper" v-if="userDetail.signature">
              <img :src="userDetail.signature" class="img-fluid" alt />
              <!-- <vs-avatar
              size="100px"
              
              id="signature"
              />-->
            </div>
            <p v-else id="signature">N/A</p>
          </div>
        </vs-col>
      </vs-row>

      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="firstName">First Name</label>
            <p id="firstName">{{ userDetail.firstName }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="lastName">Last Name</label>
            <p id="lastName">{{ userDetail.lastName }}</p>
          </div>
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-align="left"
          vs-w="6"
          v-if="
            userType === 'doctor' ||
            userType === 'nurse' ||
            userType === 'clinic'
          "
        >
          <div class="w-full m-5">
            <label for="dateOfBirth">Date Of Birth</label>
            <p id="dateOfBirth">
              {{ new Date(userDetail.dateOfBirth) | date_formatter }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="contactNumber">Contact Number</label>
            <p id="contactNumber">{{ userDetail.contactNumber }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="preferredName">Preferred Name</label>
            <p id="preferredName">{{ userDetail.preferredName }}</p>
          </div>
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-align="left"
          vs-w="6"
          v-if="userType === 'doctor'"
        >
          <div class="w-full m-5">
            <label for="abn">ABN</label>
            <p id="abn">{{ userDetail.abn }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="ahpraRegistrationNumber"
              >Ahpra Registration Number</label
            >
            <p id="ahpraRegistrationNumber">
              {{ userDetail.ahpraRegistrationNumber }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="ahpraExiryDate">Ahpra Expiry Date</label>
            <p id="ahpraExiryDate">
              {{ new Date(userDetail.ahpraExiryDate) | date_formatter }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="ahpraCertificate">Ahpra Certificate</label>
            <a
              v-if="userDetail.ahpraCertificate"
              :href="userDetail.ahpraCertificate"
              target="_blank"
            >
              <p id="ahpraCertificate">View</p>
            </a>
            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="indemnityInsuranceProvider"
              >Indemnity Insurance Provider</label
            >
            <p id="indemnityInsuranceProvider">
              {{ userDetail.indemnityInsuranceProvider }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="indemnityInsuranceNumber"
              >Indemnity Insurance Number</label
            >
            <p id="indemnityInsuranceNumber">
              {{ userDetail.indemnityInsuranceNumber }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="insuranceCertificate">Insurance Certificate</label>
            <a
              v-if="userDetail.insuranceCertificate"
              :href="userDetail.insuranceCertificate"
              target="_blank"
            >
              <p id="insuranceCertificate">View</p>
            </a>
            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="indemnityInsuranceExpiry"
              >Indemnity Insurance Expiry</label
            >
            <p id="indemnityInsuranceExpiry">
              {{
                new Date(userDetail.indemnityInsuranceExpiry) | date_formatter
              }}
            </p>
          </div>
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
          v-if="userType === 'doctor'"
        >
          <div class="w-full m-5">
            <label for="address">Address</label>
            <p id="address">
              {{
                userDetail.address ? userDetail.address.displayAddress : "N/A"
              }}
            </p>
          </div>
        </vs-col>

        <vs-col
          v-if="userType === 'doctor'"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="prescriberNumber">Prescriber Number</label>
            <p id="prescriberNumber">{{ userDetail.prescriberNumber }}</p>
          </div>
        </vs-col>

        <vs-col
          v-if="userType === 'nurse' || userType === 'clinic'"
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
        >
          <div class="w-full m-5">
            <label for="nurseCosmeticExperience"
              >Nurse Cosmetic Experience</label
            >
            <p id="nurseCosmeticExperience">
              {{ userDetail.nurseCosmeticExperience }}
            </p>
          </div>
        </vs-col>
      </vs-row>

      <vs-row
        v-if="userType === 'nurse' || userType === 'clinic'"
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
      >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="instagram">Instagram</label>
            <p id="instagram">{{ userDetail.instagram || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="facebook">Facebook</label>
            <p id="facebook">{{ userDetail.facebook || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="website">Website</label>
            <p id="website">{{ userDetail.website || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="clinicName">Clinic Name</label>
            <p id="clinicName">{{ userDetail.clinicName || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="clinicAddress1">Clinic Address 1</label>
            <p id="clinicAddress1">{{ userDetail.clinicAddress1 || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="city">City</label>
            <p id="city">{{ userDetail.city || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="stateOrRegion">State/Region</label>
            <p id="stateOrRegion">{{ userDetail.stateOrRegion || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="photoId">Photo ID</label>
            <a
              v-if="userDetail.photoId"
              :href="userDetail.photoId"
              target="_blank"
            >
              <p id="photoId">View</p>
            </a>
            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="restrictionImposed"
              >Have you ever had any restrictions imposed on your ability to
              practice?</label
            >
            <p id="restrictionImposed">
              {{ userDetail.restrictionImposed || "N/A" }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="aggreementOnAhpraRegistrationChanged"
              >Change in registration (Do you agree to tell us within 48hrs of
              any change in your registration status?)</label
            >
            <p id="aggreementOnAhpraRegistrationChanged">
              {{ userDetail.aggreementOnAhpraRegistrationChanged || "N/A" }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="aggreementOnIndemnityRegistrationChanged"
              >Change in insurance (Do you agree to tell us within 48hrs of any
              change in your insurance?)</label
            >
            <p id="aggreementOnIndemnityRegistrationChanged">
              {{ userDetail.aggreementOnIndemnityRegistrationChanged || "N/A" }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="rnOrEn"
              >Are you an RN or an EN (If an EN, you must read the guidelines on
              supervision and acknowledge the requirements involved. Contact us
              for more detail if unsure.)</label
            >
            <p id="rnOrEn">{{ userDetail.rnOrEn || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="expericedYearInRnOrEn"
              >How many years of experience have you had as an RN or an
              EN?</label
            >
            <p id="expericedYearInRnOrEn">
              {{ userDetail.expericedYearInRnOrEn || "N/A" }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="areasForRnOrEn">In what areas/fields?</label>
            <p id="areasForRnOrEn">{{ userDetail.areasForRnOrEn || "N/A" }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="cosmeticInjectorExperienceYears"
              >For how many years have you been a cosmetic injector?</label
            >
            <p id="cosmeticInjectorExperienceYears">
              {{ userDetail.cosmeticInjectorExperienceYears || "N/A" }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="cosmeticInjectableCourses"
              >Please list any courses in cosmetic injectables that you have
              completed.</label
            >
            <p id="cosmeticInjectableCourses">
              {{ userDetail.cosmeticInjectableCourses || "N/A" }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="trainingCertificate">Training Certificate</label>
            <a
              v-if="userDetail.trainingCertificate"
              :href="userDetail.trainingCertificate"
              target="_blank"
            >
              <p id="trainingCertificate">View</p>
            </a>
            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="additionalTeachingInInjectables"
              >Please list any other teaching that you have had in injectables.
              (For instance, from another nurse? A Doctor? On-the-job training?
              From a chain clinic? Please provide some detail (names, locations,
              durations))</label
            >
            <p id="additionalTeachingInInjectables">
              {{ userDetail.additionalTeachingInInjectables || "N/A" }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="averageHourInCosmeticPracticePerWeek"
              >On average, how many hours of in-clinic cosmetic practice per
              week do you currently do?</label
            >
            <p id="averageHourInCosmeticPracticePerWeek">
              {{ userDetail.averageHourInCosmeticPracticePerWeek || "N/A" }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="planForAverageHourInCosmeticPracticePerWeek"
              >How many hours per week of in-clinic practice do you plan to
              do?</label
            >
            <p id="planForAverageHourInCosmeticPracticePerWeek">
              {{
                userDetail.planForAverageHourInCosmeticPracticePerWeek || "N/A"
              }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="numberOfPatientsTreated"
              >Roughly, how many patients do you think you have treated?</label
            >
            <p id="numberOfPatientsTreated">
              {{ userDetail.numberOfPatientsTreated || "N/A" }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="confidentAreasForToxinTreatment"
              >What are your confident areas for toxin treatment?</label
            >
            <p id="confidentAreasForToxinTreatment">
              {{ confidentAreasForToxin }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="confidentAreasForFillerTreatment"
              >What are your confident areas for filler treatment?</label
            >
            <p id="confidentAreasForFillerTreatment">
              {{ confidentAreasForFiller }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="areasNotConfidentIn"
              >Are there any areas that you are not confident in?</label
            >
            <p id="areasNotConfidentIn">{{ userDetail.areasNotConfidentIn }}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="additionalAreaOfPractice"
              >Are there any other areas, products or techniques are part of
              your practice?</label
            >
            <p id="additionalAreaOfPractice">
              {{ userDetail.additionalAreaOfPractice || "N/A" }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="cv">CV</label>
            <a v-if="userDetail.cv" :href="userDetail.cv" target="_blank">
              <p id="cv">View</p>
            </a>
            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="hasCompletedHyalaseTraningInLastTwelveMonth"
              >Have you completed hyalase training in the last 12 months?</label
            >
            <p id="hasCompletedHyalaseTraningInLastTwelveMonth">
              {{ userDetail.hasCompletedHyalaseTraningInLastTwelveMonth }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="hyalaseOrComplicationManagementCertificate"
              >Relevant certificates for hyalase/complication management</label
            >
            <a
              v-if="userDetail.hyalaseOrComplicationManagementCertificate"
              :href="userDetail.hyalaseOrComplicationManagementCertificate"
              target="_blank"
            >
              <p id="hyalaseOrComplicationManagementCertificate">View</p>
            </a>
            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="hyalaseTrainingExpirationDate"
              >Hyalase Training Expiration Date</label
            >
            <p id="hyalaseTrainingExpirationDate">
              {{
                new Date(userDetail.hyalaseTrainingExpirationDate)
                  | date_formatter
              }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="hasCompletedBasicLifeSupportTrainingInLastTwelveMonth"
              >Have you completed Basic Life Support training (BLS) in the last
              12 months?</label
            >
            <p id="hasCompletedBasicLifeSupportTrainingInLastTwelveMonth">
              {{
                userDetail.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth
              }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="blsCertificate">BLS Certificate</label>
            <a
              v-if="userDetail.blsCertificate"
              :href="userDetail.blsCertificate"
              target="_blank"
            >
              <p id="blsCertificate">View</p>
            </a>
            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="blsTrainingExpirationDate"
              >BLS Training Expiration Date</label
            >
            <p id="blsTrainingExpirationDate">
              {{
                new Date(userDetail.blsTrainingExpirationDate) | date_formatter
              }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="hasImmunityToHepatitisB"
              >Has immunity to Hepatitis B</label
            >
            <p id="hasImmunityToHepatitisB">
              {{ userDetail.hasImmunityToHepatitisB || "N/A" }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="vaccinationCertificate">Vaccination Certificate</label>
            <a
              v-if="userDetail.vaccinationCertificate"
              :href="userDetail.vaccinationCertificate"
              target="_blank"
            >
              <p id="vaccinationCertificate">View</p>
            </a>
            <p v-else>N/A</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="hasReadClinicalStandardsDocuments"
              >Have you read and agree to adhere to the Fresh Clinics "Clinical
              Standards" document?</label
            >
            <p id="hasReadClinicalStandardsDocuments">
              {{ userDetail.hasReadClinicalStandardsDocuments }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="hasConfirmedDeclarations"
              >I confirm that I have made a true and accurate
              declaration.</label
            >
            <p id="hasConfirmedDeclarations">
              {{ userDetail.hasConfirmedDeclarations }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="canSendEmail">Are you happy for us to email you?</label>
            <p id="canSendEmail">{{ userDetail.canSendEmail }}</p>
          </div>
        </vs-col>
      </vs-row>

      <vs-row v-if="taskStatus === 'pending'">
        <div class="w-full m-5">
          <vs-button @click="approve" style="margin-right: 10px"
            >Approve</vs-button
          >

          <vs-button color="danger" @click="reject">Reject</vs-button>
        </div>
      </vs-row>
    </div>

    <div class="centerx">
      <vs-popup
        class="holamundo"
        title="Please specify the reason for rejection"
        :active.sync="popupActivo"
      >
        <vs-input
          name="rejectReason"
          label-placeholder="Reject Reason"
          placeholder="Reject Reason"
          v-model="rejectReason"
          class="w-full mt-6"
        />
        <span class="text-danger text-sm">
          {{ errors.first("rejectReason") }}
        </span>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
          vs-mt="6"
        >
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5" align="right">
              <vs-button
                size="lg"
                @click="rejectWithReason"
                color="primary"
                :disabled="rejectReason === ''"
                >Submit</vs-button
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button size="lg" color="danger" @click="popupActivo = false"
                >Cancel</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </vs-popup>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    rejectReason: "",
    popupActivo: false,
    defaultPP: require("@/assets/images/profile/default.png"),
    userDetail: {},
    userType: "",
    userId: "",
    taskStatus: "pending",
    confidentAreasForToxin: "",
    confidentAreasForFiller: "",
  }),
  methods: {
    ...mapActions("general", [
      "getUserDetailById",
      "approveRejectUpdatedProfile",
    ]),
    async getUserDetail(userId) {
      let self = this;
      await this.getUserDetailById(userId).then((res) => {
        const user = res.data.data;
        this.userId = user._id;
        this.userType = user.userType;

        if (!user.profileUpdated) {
          this.taskStatus = "completed";
          this.userDetail = res.data.data;
        } else {
          this.userDetail =
            res.data.data.tempProfile && res.data.data.tempProfile.length
              ? res.data.data.tempProfile[0]
              : {};
        }

        if (
          (this.userType === "nurse" || this.userType === "clinic") &&
          this.userDetail.confidentAreasForToxinTreatment !== null
        ) {
          let obj = this.userDetail.confidentAreasForToxinTreatment;

          let keys = Object.keys(obj);

          let filtered = keys.filter(function (key) {
            return obj[key];
          });

          if (filtered && filtered.length > 0)
            this.confidentAreasForToxin = filtered.join(", ");
          else this.confidentAreasForToxin = "N/A";
        }

        if (
          (this.userType === "nurse" || this.userType === "clinic") &&
          this.userDetail.confidentAreasForFillerTreatment !== null
        ) {
          let obj = this.userDetail.confidentAreasForFillerTreatment;

          let keys = Object.keys(obj);

          let filtered = keys.filter(function (key) {
            return obj[key];
          });

          if (filtered && filtered.length > 0)
            this.confidentAreasForFiller = filtered.join(", ");
          else this.confidentAreasForFiller = "N/A";
        }
      });
    },
    approve() {
      let self = this;
      self.$vs.loading();
      self
        .approveRejectUpdatedProfile({
          uid: self.userId,
          action: "approve",
        })
        .then((res) => {
          self.$vs.loading.close();
          self.$vs.notify({
            title: "Success",
            text: "Profile approved successfully !",
            color: "success",
          });
          let activeUserType = this.$store.state.AppActiveUser.userType;

          if (this.userType === "doctor") {
            const redirectRoute =
              activeUserType === "superAdmin"
                ? `/super-admin/doctors/${this.userId}/edit`
                : `/admin/doctors/${this.userId}/edit`;
            this.$router.push(redirectRoute);
          } else {
            const redirectRoute =
              activeUserType === "superAdmin"
                ? `/super-admin/nurses/${this.userId}`
                : `/admin/nurses/${this.userId}`;
            this.$router.push(redirectRoute);
            this.$router.push(redirectRoute);
          }
        })
        .catch((err) => {
          self.$vs.notify({
            title: "Error",
            text: "Process couldn't be completed at the moment !",
            color: "danger",
          });
          self.$vs.loading.close();
        });
    },
    reject() {
      this.popupActivo = true;
    },
    rejectWithReason() {
      let self = this;
      self.popupActivo = false;
      self.$vs.loading();
      self
        .approveRejectUpdatedProfile({
          uid: self.userId,
          action: "reject",
          rejectReason: self.rejectReason,
        })
        .then((res) => {
          self.$vs.loading.close();
          self.$vs.notify({
            title: "Success",
            text: "Profile rejected successfully !",
            color: "success",
          });

          let activeUserType = this.$store.state.AppActiveUser.userType;

          if (this.userType === "doctor") {
            const redirectRoute =
              activeUserType === "superAdmin"
                ? `/super-admin/doctors/${this.userId}/edit`
                : `/admin/doctors/${this.userId}/edit`;
            this.$router.push(redirectRoute);
          } else {
            const redirectRoute =
              activeUserType === "superAdmin"
                ? `/super-admin/nurses/${this.userId}`
                : `/admin/nurses/${this.userId}`;
            this.$router.push(redirectRoute);
            this.$router.push(redirectRoute);
          }
        })
        .catch((err) => {
          self.$vs.notify({
            title: "Error",
            text: "Process couldn't be completed at the moment !",
            color: "danger",
          });
          self.$vs.loading.close();
        });
    },
  },
  async created() {
    let userId = this.$route.params.userId;
    await this.getUserDetail(userId);
  },
};
</script>

<style>
p {
  font-size: 15px;
  font-weight: 500;
}

label {
  font-size: 0.8rem;
}
</style>
